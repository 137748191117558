import { Fragment, useEffect, useContext, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from "../../utils/auth.js";

import { get, ref, onValue } from "firebase/database";
import { db } from '../../utils/firebaseApp.js';

import axios from "axios";

import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import Breadcrumb from '../../components/Breadcrumb';

import ModalForNewExtraction from '../../components/utils/ModalForNewExtraction.js';
import CreateExtraction from '../../components/CreateExtraction.js';

import { Dialog, Transition } from '@headlessui/react'

import {
    PencilIcon,
} from '@heroicons/react/20/solid'

import {
    GlobeAltIcon, // For extractionLanguage
    CalendarIcon, // For extractionCreatedAt
    CubeIcon, // For extractionBatchCount (alternative for CollectionIcon)
    AdjustmentsHorizontalIcon,
    CodeBracketIcon as CustomCodeIcon,
} from '@heroicons/react/24/outline'; // Import outline icons from Heroicons v2

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(Number(dateString)).toLocaleDateString(undefined, options);
}

export default function ExtractionScreen() {
    const { currentUser } = useContext(AuthContext);

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const environment = process.env.REACT_APP_ENV;

    const { extractionId } = useParams();

    const [batches, setBatches] = useState([]);
    const [extraction, setExtraction] = useState({});

    const fetchExtraction = async () => {
        let userId = currentUser.uid;
        const databaseRef = ref(db, 'extractions/' + userId + '/' + extractionId);
        try {
            const snapshot = await get(databaseRef);
            if (snapshot.exists()) {
                setExtraction(snapshot.val());
            } else {
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchExtraction();

        let userId = currentUser.uid;
        const unsubscribe = onValue(ref(db, 'extractions/' + userId + '/' + extractionId + '/batches'), (snapshot) => {
            const data = snapshot.val();
            if (data != null) {
                setBatches([]);

                let tempBatches = [];
                Object.keys(data).forEach(function (key) {
                    let batch = data[key];
                    batch.id = key;

                    let numberOfDocs;
                    try {
                        numberOfDocs = batch.files.length;
                    } catch (error) {
                        numberOfDocs = 0;
                    }

                    let newBatch = {
                        id: batch.id,
                        name: 'Batch ' + (batch.id),
                        numberOfDocuments: numberOfDocs,
                        startDateTime: batch.startTime,
                        status: batch.status,
                    }

                    tempBatches.push(newBatch);
                });

                const sortedTempBatches = tempBatches.sort((a, b) => Number(b.startDateTime) - Number(a.startDateTime));
                setBatches(sortedTempBatches);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [extractionId]);

    const pages = [
        { name: extraction.name, href: '/extractions/' + extractionId, current: true },
    ]

    const hiddenLinkRef = useRef(null);

    const handleRowClick = (dynamicUrl) => {
        hiddenLinkRef.current.href = dynamicUrl;
        hiddenLinkRef.current.click();
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const statuses = {
        Complete: 'text-green-700 bg-green-50 ring-green-600/20',
        finished: 'text-green-700 bg-green-50 ring-green-600/20',
        'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
        processing: 'text-gray-600 bg-gray-50 ring-gray-500/10',
        Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    }

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const deleteExtraction = () => {
        currentUser.getIdToken().then((idToken) => {
            const url = serverUrl + "/delete";

            axios.post(url, {
                "extractionId": extractionId,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then((response) => {
                if (response.status === 200) {
                    console.log("hey merge");
                    window.location.href = "/extractions";
                } else {
                    console.log("hey nu merge");
                }
            }).catch((error) => {
                console.log("hey nu merge");
            });
        });
    }

    const [showDelete, setShowDelete] = useState(false);

    const handleDelete = async () => {
        setShowDelete(true);
    }

    return (
        <div className="">
            <Breadcrumb pages={pages} currentHref={"/extractions"} />
            <a ref={hiddenLinkRef} href="https://new-link.com" style={{ display: 'none' }}>a</a>

            <div className="">
                <ExtractionHeading
                    extractionName={extraction.name}
                    extractionLanguage={extraction.language}
                    extractionCreatedAt={formatDate(extraction.createdAt)}
                    extractionBatchCount={batches.length}
                    extractionStatus={extraction.status}
                    extractionFieldsCount={extraction.fields?.length || 0}
                    extractionWorkflowId={extraction.workflowId}
                    editExtraction={() => setOpen2(true)}
                    deleteExtraction={() => handleDelete()}
                />
            </div>

            <div className="sm:flex sm:items-center mt-7">
                <div className="sm:flex-auto">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">Batches</h3>
                    <p className="mt-1 text-sm text-gray-500">
                        A list of all the batches that have been uploaded to the extraction.
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        onClick={() => setOpen(true)}
                        className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Add files
                    </button>
                </div>
            </div>

            <ModalForNewExtraction open={open} setOpen={setOpen} close={() => { }}>
                {/* new batch */}
                <CreateExtraction closeModal={() => {
                    setOpen(false);
                    // window.location.reload();
                }} />
            </ModalForNewExtraction>

            <ModalForNewExtraction open={open2} setOpen={setOpen2} close={() => { }}>
                {/* edit extraction */}
                <CreateExtraction isEdit={true} closeModal={() => {
                    setOpen2(false);
                    // window.location.reload();
                    fetchExtraction();
                }} />
            </ModalForNewExtraction>

            <ShowDeleteModal open={showDelete} setOpen={setShowDelete} deleteFunction={() => {
                deleteExtraction();
                setShowDelete(false);
            }} />

            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Id
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            No. of documents
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Created at
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {batches.map((batch) => (
                                        <tr key={batch.id} className="hover:bg-gray-50 cursor-pointer" onClick={() => handleRowClick("/extractions/" + extractionId + "/batch/" + batch.id)}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {batch.id}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{batch.numberOfDocuments} {batch.numberOfDocuments === 1 ? 'document' : 'documents'}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatDate(batch.startDateTime)}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <p
                                                    className={classNames(
                                                        statuses[batch.status],
                                                        'rounded-md inline-block mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                                                    )}
                                                >
                                                    {batch.status}
                                                </p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {batches.length <= 0 &&
                            <div className="mt-4 flex-1 flex flex-col items-center justify-center h-full rounded-lg bg-gray-100 p-12">
                                <div className="text-center">
                                    <h2 className="text-xl font-semibold text-gray-900">No files yet</h2>
                                    <p className="mt-1.5 text-sm text-gray-600">
                                        Create a new batch and upload files.
                                    </p>
                                    <button
                                        onClick={() => setOpen(true)}
                                        className="mt-3 ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                        Add files
                                    </button>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

function ExtractionHeading({
    extractionName,
    extractionLanguage,
    extractionCreatedAt,
    extractionBatchCount,
    extractionStatus,
    extractionFieldsCount,
    extractionWorkflowId,
    editExtraction,
    deleteExtraction,
}) {
    const environment = process.env.REACT_APP_ENV;

    return (
        <>
            <div className="lg:flex lg:items-center lg:justify-between">
                <div className="min-w-0 flex-1">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        {extractionName}
                    </h2>
                    <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <GlobeAltIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            {extractionLanguage}
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <CubeIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            {extractionBatchCount} Batche(s)
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <AdjustmentsHorizontalIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            {extractionFieldsCount} Field(s)
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <CalendarIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            {extractionCreatedAt}
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500">
                            <CustomCodeIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <span><span className="font-semibold">Extraction ID:</span>{" "}{window.location.href.split("/").pop()}</span>
                        </div>
                        {environment === "development" && (
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                                <CustomCodeIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <span><span className="font-semibold">Workflow ID:</span>{" "}{extractionWorkflowId}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <span className="">
                        <button
                            onClick={() => editExtraction()}
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Edit extraction
                        </button>
                    </span>

                    <span className="ml-3">
                        <button
                            onClick={() => deleteExtraction()}
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            <TrashIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Delete extraction
                        </button>
                    </span>
                </div>
            </div>
        </>
    );
}

function ShowDeleteModal({ open, setOpen, deleteFunction }) {
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Confirm Deletion
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to delete this extraction? This action cannot be undone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={() => deleteFunction()}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}