import React from "react";
import { useState, useEffect, useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom';

import { getAuth } from "firebase/auth";
import { get, ref, query, startAt, endAt, orderByChild, onValue } from "firebase/database";

import { db } from '../utils/firebaseApp.js';
import app from "../utils/firebaseApp.js";

import { AuthContext } from "../utils/auth.js";

import MenuBar from '../components/MenuBar.js';

import { BarChart, DonutChart, AreaChart } from "@tremor/react";

import { Fragment } from 'react'
import { CheckIcon, CheckCircleIcon, CreditCardIcon, ExclamationCircleIcon, XMarkIcon as ErrprIcon } from '@heroicons/react/24/outline'

import { Dialog, Menu, Transition, Switch } from '@headlessui/react'

import { Switch as SwitchButton } from '@headlessui/react'

import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { PlusIcon } from '@heroicons/react/20/solid'

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Text,
  Card, Metric
} from "@tremor/react";

import TopBar from "../components/TopBar.js";

import axios from "axios";

import powered_by_stripe from './powered-by-stripe.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Billing() {
  const { currentUser } = useContext(AuthContext);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  let userId = currentUser.uid;

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [showWebhookAdd, setShowWebhookAdd] = useState(false);

  const [credits, setCredits] = useState(0);
  const [autoTopUp, setAutoTopUp] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [numberOfCredits, setNumberOfCredits] = useState(0);
  const [pricePerPage, setPricePerPage] = useState(0.1);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [showErrorApi, setShowErrorApi] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorSubtitle, setErrorSubtitle] = useState('');

  const [showGoodApi, setShowGoodApi] = useState(false);
  const [goodTitle, setGoodTitle] = useState('');
  const [goodSubtitle, setGoodSubtitle] = useState('');

  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [loadingAutoTopUp, setLoadingAutoTopUp] = useState(false);
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [loadingChangeAutoTopUp, setLoadingChangeAutoTopUp] = useState(false);

  const [showNoCard, setShowNoCard] = useState(false);

  function showErrorNotification(title, subtitle, hide = true) {
    setErrorTitle(title);
    setErrorSubtitle(subtitle);
    setShowErrorApi(true);

    if (hide) {
      setTimeout(() => {
        setShowErrorApi(false);
      }, 3000);
    }
  }

  function showGoodNotification(title, subtitle, hide = true) {
    setGoodTitle(title);
    setGoodSubtitle(subtitle);
    setShowGoodApi(true);

    if (hide) {
      setTimeout(() => {
        setShowGoodApi(false);
      }, 3000);
    }
  }

  // START CHECKOUT
  function getCookieByName(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return null;
  }

  function getClientIdFromGaCookie() {
    const gaCookie = getCookieByName('_ga');
    if (!gaCookie) return null; // Cookie not found

    const parts = gaCookie.split('.');
    if (parts.length > 2) {
      const clientId = parts.slice(2).join('.');
      return clientId;
    }

    return null; // Client ID format not as expected
  }

  function getSessionIdFromGaCookie() {
    const gaCookieValue = getCookieByName('_ga_RF4Q31QG0D'); // Use the correct cookie name
    if (!gaCookieValue) return null; // Cookie not found or unavailable

    // Parse the cookie value based on your cookie structure
    const parts = gaCookieValue.split('.');
    if (parts.length >= 3) {
      const sessionId = parts[2]; // Assuming the session ID is the third part
      return sessionId;
    }

    return null; // Session ID format not as expected or unable to parse
  }

  const handleAmountChange = (e) => {
    const value = e.target.value;

    // check if value is negative
    if (value < 0) {
      return;
    }

    // check if value has more than 2 decimal
    if (value.includes(".")) {
      const decimalIndex = value.indexOf(".");
      const decimalLength = value.length - decimalIndex - 1;

      if (decimalLength > 1) {
        return;
      }
    }

    setNumberOfCredits(value);
  };

  const createPaymentLink = async (callback) => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/stripe-create-checkout-session";

      let clientId = getClientIdFromGaCookie();
      let sessionId = getSessionIdFromGaCookie();

      let localSelectedProduct = selectedSubscription;
      let localPrice = localSelectedProduct.price;
      let localPages = localSelectedProduct.pages;

      await axios.post(url, {
        "value": localPrice,
        "pages": localPages,
        "customerId": customerId,
        "clientId": clientId,
        "sessionId": sessionId,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        callback(response.data);
      }).catch((error) => {
        callback(null);
      });
    });
  }

  const handleCheckout = async () => {
    setLoadingCheckout(true);

    // if (!numberOfCredits || numberOfCredits <= 0) {
    //   setLoadingCheckout(false);
    //   return;
    // }

    await createPaymentLink(async (response) => {
      if (!response) {
        showErrorNotification("Checkout Error", "We encountered an issue processing your request to purchase pages. If the problem continues, please contact our support team for further assistance.");
        setLoadingCheckout(false);
        return;
      }

      try {
        const url = response.url;
        window.open(url, "_self");
      } catch (error) {
        showErrorNotification("Checkout Error", "We encountered an issue processing your request to purchase pages. If the problem continues, please contact our support team for further assistance.");
        setLoadingCheckout(false);
      }
    });
  }

  const createPaymentLinkSubscription = async (callback) => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/stripe-create-checkout-session-subscription";

      let clientId = getClientIdFromGaCookie();
      let sessionId = getSessionIdFromGaCookie();

      let localSelectedProduct = selectedSubscription;
      let localPrice = localSelectedProduct.price;
      let localPages = localSelectedProduct.pages;

      // discount 15% for subscriptions
      localPrice = localPrice * 0.85;

      await axios.post(url, {
        "value": localPrice,
        "pages": localPages,
        "productId": selectedPriceId,
        "customerId": customerId,
        "clientId": clientId,
        "sessionId": sessionId,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        callback(response.data);
      }).catch((error) => {
        callback(null);
      });
    });
  }

  const handleSubscription = async () => {
    setLoadingCheckout(true);

    await createPaymentLinkSubscription(async (response) => {
      if (!response) {
        showErrorNotification("Checkout Error", "We encountered an issue processing your request to purchase pages. If the problem continues, please contact our support team for further assistance.");
        setLoadingCheckout(false);
        return;
      }

      try {
        const url = response.url;
        window.open(url, "_self");
      } catch (error) {
        showErrorNotification("Checkout Error", "We encountered an issue processing your request to purchase pages. If the problem continues, please contact our support team for further assistance.");
        setLoadingCheckout(false);
      }
    });
  }
  // END CHECKOUT

  // START CUSTOMER PORTAL
  const createPortalSession = async (callback) => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/stripe-create-portal-session";

      await axios.post(url, {
        "customerId": customerId,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then(async (response) => {
        await callback(response.data);
      }).catch(async (error) => {
        await callback(null);
      });
    });
  }

  const handlePortal = async () => {
    setLoadingPortal(true);

    await createPortalSession(async (response) => {
      if (!response) {
        showErrorNotification("Portal Unavailable", "We're currently unable to access the customer portal. Please try again later. If the issue persists, contact our support team for assistance.");
        setLoadingPortal(false);
        return;
      }

      try {
        const url = response.url;
        window.open(url, "_self");
      } catch (error) {
        showErrorNotification("Portal Unavailable", "We're currently unable to access the customer portal. Please try again later. If the issue persists, contact our support team for assistance.");
        setLoadingPortal(false);
      }
    });
  }
  // END CUSTOMER PORTAL

  // START AUTO TOP UP
  const createTopUp = async (data) => {
    setLoadingChangeAutoTopUp(true);

    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/stripe-create-auto-top-up";

      let isModify = autoTopUp ? true : false;
      let isRemove = data ? false : true;

      await axios.post(url, {
        "userId": userId,
        "customerId": customerId,
        "remove": isRemove,
        "modify": isModify,
        "credits_below": data ? data.creditsBelow : null,
        "credits_back_up": data ? data.creditsBackUp : null,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        // if is modify
        if (isModify) {
          showGoodNotification("Auto Top-Up Updated", "Your auto top-up settings have been successfully updated.");
        }

        // if is remove
        if (isRemove) {
          showGoodNotification("Auto Top-Up Removed", "Auto top-up has been removed from your account. You will no longer be automatically charged to recharge your balance.");
        }

        // if is add
        if (data) {
          showGoodNotification("Auto Top-Up Enabled", "You have successfully added auto top-up to your account. Your balance will automatically be recharged based on your settings.");
        }
      }).catch((error) => {
        showErrorNotification("Connection Error", "We're unable to connect to our servers at the moment. Try again later. If the problem persists, please contact our support team for assistance.");
      });
    });

    setLoadingChangeAutoTopUp(false);
  }

  const checkAutoTopUp = async (customerId, callback) => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/stripe-check-auto-top-up";

      await axios.post(url, {
        "customerId": customerId,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        callback(response.data);
      }).catch((error) => {
        callback(null);
      });
    });
  }

  const createCustomer = async (callback) => {
    await currentUser.getIdToken().then(async (idToken) => {
      const url = serverUrl + "/stripe-create-customer";

      await axios.post(url, {}, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then(async (response) => {
        await callback(response.data);
      }).catch(async (error) => {
        await callback(null);
      });
    });
  }

  const handleAutoTopUp = async () => {
    setLoadingAutoTopUp(true);

    if (customerId === null) {
      await createCustomer(async (response) => {
        if (!response) {
          showErrorNotification("Connection Error", "We're unable to connect to our servers at the moment. Try again later. If the problem persists, please contact our support team for assistance.");
          return;
        }

        let localCustomerId = response.customerId;
        setCustomerId(localCustomerId);

        await checkAutoTopUp(localCustomerId, (response) => {
          if (!response) {
            setShowNoCard(true);
            setLoadingAutoTopUp(false);
            return
          } else {
            setShowWebhookAdd(true);
          }
        });
      });
    } else {
      await checkAutoTopUp(customerId, (response) => {
        if (!response) {
          setShowNoCard(true);
        } else {
          setShowWebhookAdd(true);
        }
      });
    }

    setLoadingAutoTopUp(false);
  }

  const location = useLocation();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [userSubscription, setUserSubscription] = useState(null);

  useEffect(() => {
    const appName = process.env.REACT_APP_NAME;
    document.title = "Billing - " + appName;

    // credits price
    const creditsRef = ref(db, 'credits');
    const unsubscribe = onValue(creditsRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        let rawPricePerPage = data.costPerPage || 0;
        let processedPricePerPage = rawPricePerPage / 100;

        setPricePerPage(processedPricePerPage);
      }
    });

    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');

    if (success === 'true') {
      setShowSuccess(true);
      window.history.replaceState({}, document.title, "/billing");
    } else if (success === 'false') {
      setShowError(true);
      window.history.replaceState({}, document.title, "/billing");
    }

    // user credits object
    const creditsRef2 = ref(db, 'userDetails/' + userId + '/credits');
    const unsubscribe2 = onValue(creditsRef2, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        let rawCredits = data.credits || 0;
        let processedCredits = rawCredits / 100;
        let customerId = data.customerId || null;
        let rawAutoTopUp = data.autoTopUp || null;

        if (rawAutoTopUp) {
          rawAutoTopUp.credits_below = rawAutoTopUp.credits_below / 100;
          rawAutoTopUp.credits_back_up = rawAutoTopUp.credits_back_up / 100;
        }

        setCredits(processedCredits);
        setCustomerId(customerId);
        setAutoTopUp(rawAutoTopUp);

        let localSubscriptions = data.subscriptions || null;
        if (localSubscriptions) {
          // subscriptions has multiple keys inside. i need to take the first one
          let keys = Object.keys(localSubscriptions);
          let firstKey = keys[0];
          let localSubscription = localSubscriptions[firstKey];

          setUserSubscription(localSubscription);
        } else {
          setUserSubscription(null);
        }
      }
    });

    return () => {
      unsubscribe();
      unsubscribe2();
    }
  }, [userId]);

  const showBuyMoreCredits = false;
  const showAutoTopUp = false;
  const showCustomerPortal = true;
  const showSubscription = true;

  const subscriptions = [
    {
      "pages": 100,
      "price": 19,
      "priceId": "price_1PPxpJJhJ8d6WtokEBDNsx02",
      "productId": "prod_QGUoog9p1KCPZq",
    },
    {
      "pages": 250,
      "price": 39,
      "priceId": "price_1PPxpfJhJ8d6WtokkpEJ2wEf",
      "productId": "prod_QGUpAjvHeK1ZrQ",
    },
    {
      "pages": 500,
      "price": 69,
      "priceId": "price_1PPxq4JhJ8d6WtokktSOR9Tp",
      "productId": "prod_QGUpwogHpSzPIR",
    },
    {
      "pages": 1000,
      "price": 129,
      "priceId": "price_1PPxr6JhJ8d6WtokzHcmAgJg",
      "productId": "prod_QGUqRAqrHdTvfm",
    },
    {
      "pages": 2000,
      "price": 219,
      "priceId": "price_1PPxrTJhJ8d6Wtokgk6IUUEw",
      "productId": "prod_QGUrr6GgkUgAJm",
    },
    {
      "pages": 5000,
      "price": 429,
      "priceId": "price_1PPxrtJhJ8d6WtokFOgejvus",
      "productId": "prod_QGUrMwJM1WBCQC",
    },
    {
      "pages": 10000,
      "price": 819,
      "priceId": "price_1PPxsIJhJ8d6WtokAVswWEWa",
      "productId": "prod_QGUs72NvosNyPE",
    },
    {
      "pages": 25000,
      "price": 1890,
      "priceId": "price_1PPxshJhJ8d6WtokESLxOQoP",
      "productId": "prod_QGUsXrcqGbp9bj",
    },
    {
      "pages": 50000,
      "price": 3450,
      "priceId": "price_1PPxt1JhJ8d6WtokLcsW9h9A",
      "productId": "prod_QGUs4ycz2DsHkA",
    },
  ];

  const [selectedPriceId, setSelectedPriceId] = useState(subscriptions[0].priceId);
  const [selectedSubscription, setSelectedSubscription] = useState(subscriptions[0]);

  const changeSelectedPriceId = (priceId) => {
    // check if priceId is in subscriptions
    let found = false;
    subscriptions.forEach((subscription) => {
      if (subscription.priceId === priceId) {
        found = true;
      }
    });

    if (!found) {
      return;
    }

    setSelectedPriceId(priceId);
    setSelectedSubscription(subscriptions.find(subscription => subscription.priceId === priceId));
  }

  const checkSubscriptionCanceled = (subscriptionData) => {
    // check if has .canceled and is true
    if (subscriptionData.canceled === true) {
      return true;
    }

    return false;
  }

  const formatDateForBilling = (date) => {
    let formattedDate = new Date(date * 1000);
    let formattedString = formattedDate.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    return formattedString;
  }

  return (
    <>
      <div>
        <MenuBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} currentHref="billing" />

        <FailedBilling show={showError} setShow={setShowError} />
        <SuccessfulBilling show={showSuccess} setShow={setShowSuccess} />

        <ErrorNotification
          show={showErrorApi}
          setShow={setShowErrorApi}
          title={errorTitle}
          subtitle={errorSubtitle}
        />

        <GoodNotification
          show={showGoodApi}
          setShow={setShowGoodApi}
          title={goodTitle}
          subtitle={goodSubtitle}
        />

        <ShowNoCardAdded
          show={showNoCard}
          setShow={setShowNoCard}
          openCustomerPortal={() => {
            setShowNoCard(false);
            handlePortal();
          }}
        />

        <ModalWebhook
          open={showWebhookAdd}
          setOpen={() => {
            // canceled
            setShowWebhookAdd(false);
          }}
          addFunction={async (data) => {
            await createTopUp(data);
            setShowWebhookAdd(false);
          }}
          removeFunction={async () => {
            await createTopUp(null);
            setShowWebhookAdd(false);
          }}
          autoTopUp={autoTopUp}
          loadingChangeAutoTopUp={loadingChangeAutoTopUp}
        />

        <main className="lg:pl-72">
          <TopBar setSidebarOpen={setSidebarOpen} />

          <div className="py-10 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-start">
              <div className='px-3'>
                <a href='/dashboard' className="text-4xl font-bold text-gray-900">Billing</a>
                {/* <p className="text-m text-gray-500"></p> */}
              </div>


              <div className='w-full px-3 mt-5'>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
                  {/* see credits */}
                  {/* <div className="overflow-hidden rounded-lg bg-white border shadow-sm px-4 py-5 sm:p-6 sm:col-span-1">
                    <dt className="mt-1.5 truncate text-sm font-medium text-gray-500">Current credits</dt>

                    <dd className="mt-4 text-3xl font-semibold tracking-tight text-gray-900">${credits.toFixed(2)}</dd>
                    <dt className="truncate text-sm font-medium text-gray-500">equivalent of</dt>
                    <dd className="text-3xl font-semibold tracking-tight text-gray-900">{Math.floor(credits / pricePerPage)} pages</dd>
                  </div> */}
                  <div className="overflow-hidden rounded-lg bg-white border shadow-sm px-4 py-5 sm:p-6 sm:col-span-1">
                    <dt className="mt-1.5 truncate text-sm font-medium text-gray-500">Number of Pages</dt>
                    <dd className="text-3xl font-semibold tracking-tight text-gray-900">{(credits * 10).toLocaleString()} pages</dd>
                  </div>

                  {/* buy credits */}
                  {showBuyMoreCredits && (
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6 sm:col-span-3">
                      {/* title */}
                      <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center">
                          <dt className="truncate text-sm font-medium text-gray-500">Buy more credits</dt>
                          <div className="ml-3">
                            <img src={powered_by_stripe} alt="My Icon" height={100} width={100} />
                          </div>
                        </div>
                        <div className="flex flex-row items-center place-content-end text-2xl font-semibold tracking-tight text-gray-900">
                          ${pricePerPage.toFixed(2)} / page
                        </div>
                      </div>

                      {/* dollars */}
                      <div className="mt-1 flex flex-row items-center justify-between">
                        <div className="flex-grow relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            name="price"
                            id="price"
                            className="block w-full rounded-md border-gray-300 py-2 pl-7 pr-12 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="0.00"
                            aria-describedby="price-currency"
                            value={numberOfCredits}
                            onChange={handleAmountChange}
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                              USD
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* what you will get - number of pages - put inside a box, not input */}
                      <div className="mt-5">
                        <div className="text-sm font-medium text-gray-500">You will get</div>
                        <div className="text-3xl font-semibold tracking-tight text-gray-900">{Math.floor((numberOfCredits || 0) / pricePerPage)} pages</div>
                      </div>

                      {/* button */}
                      <div className="mt-5">
                        {(!numberOfCredits || numberOfCredits <= 0) && (
                          <button
                            disabled={true}
                            className="w-full rounded-md bg-gray-400 px-4 py-2 text-sm font-semibold text-white shadow-sm cursor-not-allowed focus:outline-none"
                          >
                            Proceed to checkout
                          </button>

                        )}
                        {(numberOfCredits > 0) && (
                          <button
                            onClick={() => handleCheckout()}
                            className="w-full flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={loadingCheckout}
                          >
                            <>
                              {loadingCheckout && (
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.313 1.344 6.315 3.514 8.485l2.486-2.194z"></path>
                                </svg>
                              )}
                              Proceed to checkout
                            </>
                          </button>
                        )}
                      </div>
                    </div>
                  )}

                  {/* auto charge */}
                  {showAutoTopUp && (
                    <div className="overflow-hidden rounded-lg bg-white border shadow-sm px-4 py-5 sm:p-6 flex flex-col sm:col-span-2">
                      {autoTopUp && (
                        <dt className="mt-1.5 truncate text-sm font-medium text-gray-500 flex">
                          <span className="text-green-500 mr-1">
                            <CheckCircleIcon className="h-5 w-5" />
                          </span>
                          <span>
                            Auto Top-Up is enabled
                          </span>
                        </dt>
                      )}

                      {!autoTopUp && (
                        <dt className="mt-1.5 truncate text-sm font-medium text-gray-500 flex">
                          <span>
                            Auto Top-Up
                          </span>
                        </dt>
                      )}

                      <div className="mt-4 flex flex-col justify-between flex-grow">
                        {autoTopUp && (
                          <div className="text-sm text-gray-500">
                            When your credit balance falls below ${autoTopUp.credits_below}, your card will be automatically recharged to ${autoTopUp.credits_back_up}.
                          </div>
                        )}

                        {!autoTopUp && (
                          <div className="text-sm text-gray-500">
                            Enable auto top-up to automatically recharge your balance when it falls below a certain amount.
                          </div>
                        )}

                        <div className="mt-4 flex flex-row items-center justify-between">
                          <button
                            onClick={() => handleAutoTopUp()}
                            className="w-full flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={loadingAutoTopUp}
                          >
                            <>
                              {loadingAutoTopUp && (
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.313 1.344 6.315 3.514 8.485l2.486-2.194z"></path>
                                </svg>
                              )}
                              {autoTopUp ? "Modify Auto Top-Up" : "Enable Auto Top-Up"}
                            </>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* subscription */}
                  {showSubscription && userSubscription !== null && (
                    <div className="overflow-hidden rounded-lg bg-white border shadow-sm px-4 py-5 sm:p-6 flex flex-col sm:col-span-3">
                      <dt className="mt-1.5 truncate text-sm font-medium text-gray-500 flex justify-between">
                        Current Subscription
                        <div className="ml-3">
                          {userSubscription.status === "active" && !checkSubscriptionCanceled(userSubscription) && (
                            <span className="text-green-500 flex items-center">
                              <CheckCircleIcon className="h-5 w-5 mr-1" />
                              Active
                            </span>
                          )}
                          {userSubscription.status === "active" && checkSubscriptionCanceled(userSubscription) && (
                            <span className="text-yellow-500 flex items-center">
                              <ExclamationCircleIcon className="h-5 w-5 mr-1" />
                              Canceled
                            </span>
                          )}
                        </div>
                      </dt>

                      <div className="text-3xl font-semibold flex flex-row items-end justify-start">
                        <span>${(userSubscription.plan.price / 100).toFixed(2)}</span>
                        <div className="ml-2 text-lg font-semibold text-gray-500 lg:ml-2">/ month</div>
                        <div className="ml-2 text-lg font-semibold text-gray-500 lg:ml-2">({userSubscription.plan.pages} pages)</div>
                      </div>

                      <div className="mt-3 flex flex-col justify-between flex-grow">
                        <div className="text-sm text-gray-500">
                          {checkSubscriptionCanceled(userSubscription) && (
                            <>
                              <span>
                                {" "}Your subscription has been canceled and will expire on {formatDateForBilling(userSubscription.period.endDate)}. You can renew your subscription now.
                              </span>
                              <div className="">
                                <button
                                  className="text-sm underline text-indigo-500 hover:text-indigo-700 focus:outline-none"
                                  onClick={() => handlePortal()}
                                >
                                  Renew Subscription
                                </button>
                              </div>
                            </>
                          )}

                          {!checkSubscriptionCanceled(userSubscription) && (
                            <>
                              <span>
                                {" "}Your subscription will automatically renew on {formatDateForBilling(userSubscription.period.endDate)}.
                                {" "}You can cancel your subscription at any time.
                              </span>
                              <div className="">
                                <button
                                  className="text-sm underline text-indigo-500 hover:text-indigo-700 focus:outline-none"
                                  onClick={() => handlePortal()}
                                >
                                  Cancel Subscription
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* customer portal */}
                  {showCustomerPortal && (
                    <div className="overflow-hidden rounded-lg bg-white border shadow-sm px-4 py-5 sm:p-6 flex flex-col sm:col-span-2">
                      <dt className="mt-1.5 truncate text-sm font-medium text-gray-500 flex">
                        Customer Portal
                        <div className="ml-3">
                          <img src={powered_by_stripe} alt="My Icon" height={100} width={100} />
                        </div>
                      </dt>

                      <div className="mt-3 flex flex-col justify-between flex-grow">
                        {/* manage payment methods, billing information and see invoice history */}
                        <div className="text-sm text-gray-500">
                          Manage your payment methods, billing information and see your invoice history.
                        </div>

                        <button
                          onClick={() => handlePortal()}
                          className="mt-4 w-full flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                          disabled={loadingPortal}
                        >
                          <>
                            {loadingPortal && (
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.313 1.344 6.315 3.514 8.485l2.486-2.194z"></path>
                              </svg>
                            )}
                            Go to Customer Portal
                          </>
                        </button>
                      </div>
                    </div>
                  )}

                  {/* choose subscription */}
                  {showSubscription && (
                    <div className="overflow-hidden rounded-lg bg-white border shadow-sm px-4 py-5 sm:p-6 flex flex-col sm:col-span-4">
                      <SubscriptionContainer
                        selectedPriceId={selectedPriceId}
                        subscriptions={subscriptions}
                        changeSelectedPriceId={changeSelectedPriceId}
                        handleSubscription={handleSubscription}
                        handleCheckout={handleCheckout}
                        loadingCheckout={loadingCheckout}
                        onlyAddPages={userSubscription !== null}
                      />
                    </div>
                  )}

                  {/* choose subscription */}
                  {/* {showSubscription && userSubscription === null && (
                    <SubscriptionContainer2
                      selectedPriceId={selectedPriceId}
                      subscriptions={subscriptions}
                      changeSelectedPriceId={changeSelectedPriceId}
                      handleSubscription={handleSubscription}
                    />
                  )} */}
                  {/* end */}
                </dl>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

const SubscriptionContainer = ({ selectedPriceId, subscriptions, changeSelectedPriceId, handleSubscription, handleCheckout, loadingCheckout, onlyAddPages }) => {
  const selectedSubscription = subscriptions.find(subscription => subscription.priceId === selectedPriceId);
  const subscriptionPrice = selectedSubscription ? selectedSubscription.price : 0;
  const subscriptionPages = selectedSubscription ? selectedSubscription.pages : 0;

  const [enabled, setEnabled] = useState(false);

  const subscriptionDiscount = enabled ? 0.15 : 0;
  const subscriptionDiscountedPrice = subscriptionPrice - (subscriptionPrice * subscriptionDiscount);
  const subscriptionPricePerPage = subscriptionDiscountedPrice / subscriptionPages;

  const discountAmount = subscriptionPrice * subscriptionDiscount;

  return (
    <div className="w-full">
      <dt className="mt-1.5 truncate text-sm font-medium text-gray-500 flex">
        Buy Pages
        <div className="ml-3">
          <img src={powered_by_stripe} alt="My Icon" height={100} width={100} />
        </div>
      </dt>

      <hr className="mt-4 border-t-1 border-gray-200" />

      <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
        <div className="lg:col-span-2">
          <div className="mt-1 flex items-center justify-center">
            <p className="text-sm font-semibold text-gray-500">CHOOSE A PACKAGE</p>
          </div>

          <div className="mt-5 grid grid-cols-2 lg:grid-cols-3 gap-4">
            {subscriptions.map((subscription, index) => (
              <SubscriptionCard
                key={index}
                amount={subscription.pages}
                price={subscription.price}
                priceId={subscription.priceId}
                productId={subscription.productId}
                selectedPriceId={selectedPriceId}
                onClick={changeSelectedPriceId}
              />
            ))}
          </div>
        </div>

        <div className="lg:col-span-1">
          <div className="flex flex-col justify-between h-full px-4">
            <div className="flex flex-col items-center justify-center flex-grow">
              <div className="">
                {onlyAddPages && (
                  <div className="mb-3 mt-3 lg:mt-0">
                    <p className="text-md font-bold text-indigo-500">Pay-As-You-Go</p>
                  </div>
                )}

                {!onlyAddPages && (
                  <div className="mb-6 mt-5 lg:mt-0 flex flex-row items-center justify-center">
                    <p className="text-md font-bold text-indigo-500">Pay-As-You-Go</p>
                    <SwitchButton
                      disabled={onlyAddPages}
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        onlyAddPages ? 'bg-gray-300' : (enabled ? 'bg-indigo-300' : 'bg-indigo-300'),
                        'mx-3 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          onlyAddPages ? 'bg-gray-500' : 'bg-indigo-500',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </SwitchButton>
                    <p className="text-md font-bold text-indigo-500">Monthly</p>
                  </div>
                )}
              </div>

              <div className={classNames(
                "flex flex-row items-center justify-evenly w-full",
              )}>
                {enabled && (
                  <>
                    <div className="text-lg font-bold text-gray-500 line-through decoration-red-500 opacity-50">
                      ${subscriptionPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </div>
                    <div className="bg-green-500/30 text-green-500 text-sm font-bold px-2 py-1 rounded-lg">
                      SAVE ${discountAmount.toFixed(2)}
                    </div>
                  </>
                )}
              </div>

              <div className={classNames(
                "mt-1 text-4xl font-bold flex flex-col lg:flex-row items-center lg:items-end justify-center",
                enabled ? '' : 'py-3.5'
              )}>
                <span>${subscriptionDiscountedPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                {enabled && (
                  <div className="ml-2 text-lg font-semibold text-gray-500 lg:ml-2">/ month</div>
                )}
              </div>

              <div className="mt-5 flex flex-col items-center">
                <p className="text-xs font-semibold text-gray-500">COST PER PAGE</p>
                <div className="mt-0.5 text-sm font-bold">${subscriptionPricePerPage.toFixed(5)}</div>
              </div>
            </div>

            <div className="mt-auto text-center w-full">
              <div className="flex justify-between w-full text-sm font-semibold text-gray-700">
                <span>{subscriptionPages.toLocaleString()} pages</span>
                <span>${subscriptionDiscountedPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
              <hr className="my-2 border-t-1 border-gray-200" />
              <div className="flex justify-between w-full text-md font-bold">
                <span>Total</span>
                <span>${subscriptionDiscountedPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-7 border-t-1 border-gray-200" />

      <div className="mt-6 flex flex-row items-center justify-between">
        <button
          className={classNames(
            "rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm focus:outline-none ml-auto",
            loadingCheckout && 'cursor-not-allowed opacity-50'
          )}
          onClick={enabled ? handleSubscription : handleCheckout}
          disabled={loadingCheckout}
        >
          {enabled ? 'Subscribe' : 'Continue to Checkout'}
        </button>
      </div>
    </div>
  );
};

const SubscriptionCard = ({ amount, price, priceId, productId, selectedPriceId, onClick }) => {
  return (
    <button
      className={`px-8 py-6 rounded-lg border transition-all duration-300 ${selectedPriceId === priceId
        ? 'border-2 border-indigo-500 bg-indigo-100'
        : 'border border-gray-300 hover:border-indigo-500'
        }`}
      onClick={() => onClick(priceId)}
    >
      <div className="text-2xl font-semibold">{amount.toLocaleString()}</div>
      <div className="text-sm text-gray-500">Pages</div>
    </button>
  );
}

{/* <div className="mt-5 relative flex items-center">
<div className="flex-grow border-t border-gray-200"></div>
<p className="mx-4 text-sm font-semibold text-gray-500">OR ENTER AN AMOUNT OF PAGES</p>
<div className="flex-grow border-t border-gray-200"></div>
</div>

<div className="mt-4 mb-1 flex flex-row items-center justify-center">
<input
type="number"
value={credits}
onChange={(e) => setCredits(Number(e.target.value))}
className="border rounded-lg"
/>
</div> */}

function ErrorNotification({ show, setShow, title, subtitle }) {
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-100 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

function GoodNotification({ show, setShow, title, subtitle }) {
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-100 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
                  </div>
                  {/* <div className="ml-4 flex flex-shrink-0">
                      <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                              setShowError(false)
                          }}
                      >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                  </div> */}
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

function ShowNoCardAdded({ show, setShow, openCustomerPortal }) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-indigo-100">
                    {/* show no card added */}
                    <CreditCardIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      No payment method added
                    </Dialog.Title>
                    <div className="mt-2">
                      {/* say he need to go to customer portal by stripe to add a card */}
                      <p className="text-sm text-gray-500">
                        You need to add a payment method to enable auto top-up. Please add a payment method in your customer portal.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={() => openCustomerPortal()}
                  >
                    Go to customer portal
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setShow(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function ModalWebhook({ open, setOpen, addFunction, removeFunction, autoTopUp, loadingChangeAutoTopUp }) {
  const cancelButtonRef = useRef(null);
  const formRef = useRef(null);

  const [creditsBelow, setCreditsBelow] = useState('');
  const [creditsBelowError, setCreditsBelowError] = useState(null);

  const [creditsBackUp, setCreditsBackUp] = useState('');
  const [creditsBackUpError, setCreditsBackUpError] = useState(null);

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (autoTopUp) {
      setCreditsBelow(autoTopUp.credits_below);
      setCreditsBackUp(autoTopUp.credits_back_up);
      setEnabled(true);
    } else {
      clearFunction();
    }
  }, [autoTopUp, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'creditsBelow') {
      setCreditsBelow(value);
      setCreditsBelowError(value < 5);
    }

    if (name === 'creditsBackUp') {
      setCreditsBackUp(value);
      setCreditsBackUpError(value < 10);
    }
  };

  const checkValidity = () => {
    if (creditsBelow < 5 && creditsBelow !== '') {
      setCreditsBelowError(true);
    } else {
      setCreditsBelowError(false);
    }

    if (creditsBackUp < 10 && creditsBackUp !== '') {
      setCreditsBackUpError(true);
    } else {
      setCreditsBackUpError(false);
    }
  }

  const saveFunction = async () => {
    // if not enabled focus on the checkbox and return
    if (!enabled) {
      await removeFunction();
      return;
    }

    if (formRef.current.checkValidity()) {
      if (creditsBelow < 5) {
        formRef.current[1].focus();
        return;
      }

      if (creditsBackUp < 10) {
        formRef.current[2].focus();
        return;
      }

      await addFunction({
        creditsBelow: creditsBelow,
        creditsBackUp: creditsBackUp,
      });

      clearFunction();
    } else {
      if (creditsBelow < 5) {
        formRef.current[1].focus();
        return;
      }

      if (creditsBackUp < 10) {
        formRef.current[2].focus();
        return;
      }
    }
  }

  const enableCheckbox = () => {
    if (enabled) {
      setCreditsBelowError(null);
      setCreditsBackUpError(null);
    } else {
      checkValidity();
    }
    setEnabled(!enabled);
  }

  const clearFunction = () => {
    setCreditsBelow('');
    setCreditsBelowError(null);

    setCreditsBackUp('');
    setCreditsBackUpError(null);

    setEnabled(false);
  }

  const cancelFunction = () => {
    clearFunction();
    setOpen(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={async () => {
        if (!loadingChangeAutoTopUp) {
          setOpen(false)
        }
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="w-full mt-3 text-center sm:mt-0 sm:text-left"> {/* sm:ml-4 */}
                      <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                        Auto Top-Up settings
                      </Dialog.Title>

                      <form className="mt-4 sm:flex sm:items-center flex-col" ref={formRef}>
                        <Dialog.Description as="div" className="text-sm text-gray-500">
                          Would you like to set up automatic top-up for your account?

                          <Switch.Group as="div" className="flex items-center mb-4">
                            <Switch
                              disabled={loadingChangeAutoTopUp}
                              checked={enabled}
                              onChange={enableCheckbox}
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              <span className="sr-only">Use setting</span>
                              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                )}
                              />
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  enabled ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                )}
                              />
                            </Switch>
                            <Switch.Label as="span" className="ml-3 mt-1">
                              <span className="text-sm text-gray-500">Yes, automatically recharge my card when my credit balance falls below a threshold</span>
                            </Switch.Label>
                          </Switch.Group>
                        </Dialog.Description>

                        <div className="w-full">
                          <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                            When credit balance goes below
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                              type="number"
                              name="creditsBelow"
                              id="creditsBelow"
                              value={creditsBelow}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                              placeholder="5.00"
                              aria-describedby="price-currency"
                              required={true}
                              disabled={!enabled || loadingChangeAutoTopUp}
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span className="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                              </span>
                            </div>
                          </div>
                          <label htmlFor="creditsBelow" className={`text-xs transition-colors ${creditsBelowError ? 'text-red-500' : 'text-gray-500'}`}>
                            Enter an amount bigger than $5
                          </label>
                        </div>

                        <div className="w-full mt-3 mb-3">
                          <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                            Bring credit balance back up to
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                              type="number"
                              name="creditsBackUp"
                              id="creditsBackUp"
                              value={creditsBackUp}
                              onChange={handleInputChange}
                              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                              placeholder="10.00"
                              aria-describedby="price-currency"
                              required={true}
                              disabled={!enabled || loadingChangeAutoTopUp}
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                              <span className="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                              </span>
                            </div>
                          </div>
                          <label htmlFor="email" className={`text-xs transition-colors ${creditsBackUpError ? 'text-red-500' : 'text-gray-500'}`}>
                            Enter an amount bigger than $10
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={() => saveFunction()}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={loadingChangeAutoTopUp}
                  >
                    <>
                      {loadingChangeAutoTopUp && (
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.313 1.344 6.315 3.514 8.485l2.486-2.194z"></path>
                        </svg>
                      )}
                      Save settings
                    </>
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={() => cancelFunction()}
                    ref={cancelButtonRef}
                    disabled={loadingChangeAutoTopUp}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function SuccessfulBilling({ show, setShow }) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Payment Successful
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Your payment has been processed. Your pages will be updated soon. Thank you for your purchase!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setShow(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function FailedBilling({ show, setShow }) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <ErrprIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Payment failed
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Your payment could not be processed. Please check your details and try again.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setShow(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

{/* payment methods */ }
{/* <div className="overflow-hidden rounded-lg bg-white border shadow-sm px-4 py-5 sm:p-6">
  <dt className="mt-1.5 truncate text-sm font-medium text-gray-500">Payment methods</dt>

  <div className="mt-4">
    {paymentMethods.length === 0 && (
      <div className="text-sm text-gray-500">No payment methods added</div>
    )}

    {paymentMethods.map((paymentMethod, index) => (
      <div key={index} className="flex flex-row items-center justify-between mb-1">
        <dt className="truncate text-sm font-medium text-gray-500">{paymentMethod.type}</dt>
        <div className="flex flex-row items-center place-content-end">
          <div className="flex flex-col">
            <div>
              ****{paymentMethod.card.last4}
            </div>
            <div>
              Expires {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
</div> */}