import { Fragment, useState, useEffect } from 'react'

import { Combobox } from '@headlessui/react'
import { ArrowLeftIcon, CheckIcon, ChevronUpDownIcon, GlobeAmericasIcon as GlobeIcon } from '@heroicons/react/20/solid'

import {
  RO, US, FR, ES, SA, PT, DE, IT, RU, UA, PL, CZ, HU, BG, RS, HR, TR, IN, NP, VN, PH, PK, BD
} from 'country-flag-icons/react/3x2'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function FirstStep({ nextStep, parentParams, previousStep, isEdit }) {
  const [name, setName] = useState(parentParams?.name || '');
  const [description, setDescription] = useState(parentParams?.description || '');
  const [langObject, setLangObject] = useState({ id: 0, name: 'Multi-Lingual' });
  const [workflowId, setWorkflowId] = useState(parentParams?.workflowId || null);

  let languages = [
    { id: 0, name: 'Multi-Lingual' },
    { id: 1, name: 'Romanian' },
    { id: 2, name: 'English' },
    { id: 3, name: 'French' },
    { id: 4, name: 'Spanish' },
    { id: 5, name: 'Arabic' },
    { id: 6, name: 'Portuguese' },
    { id: 7, name: 'German' },
    { id: 8, name: 'Italian' },
    { id: 9, name: 'Russian' },
    { id: 10, name: 'Ukrainian' },
    { id: 11, name: 'Polish' },
    { id: 12, name: 'Czech' },
    { id: 13, name: 'Hungarian' },
    { id: 14, name: 'Bulgarian' },
    { id: 15, name: 'Serbian' },
    { id: 16, name: 'Croatian' },
    { id: 17, name: 'Turkish' },
    { id: 18, name: 'Hindi' },
    { id: 19, name: 'Nepali' },
    { id: 20, name: 'Vietnamese' },
    { id: 21, name: 'Filipino' },
    { id: 22, name: 'Urdu' },
    { id: 23, name: 'Bangla' },
  ];

  const prepareOptions = (parentOptions) => {
    let objectReturn = {};

    if (!parentOptions || parentOptions === null || parentOptions === undefined) {
      objectReturn = {
        hasTable: false,
        handwrittenTextRecognition: false,
        checkboxRecognition: false,
        specificPageProcessing: false,
        specificPageProcessingOptions: { from: 1, to: 2 }, // Default values
      };
    } else {
      objectReturn = {
        hasTable: parentOptions?.hasTable || false,
        handwrittenTextRecognition: parentOptions?.handwrittenTextRecognition || false,
        checkboxRecognition: parentOptions?.checkboxRecognition || false,
        specificPageProcessing: parentOptions?.specificPageProcessing || false,
        specificPageProcessingOptions: parentOptions?.specificPageProcessingOptions || { from: 1, to: 2 },
      };
    }

    return objectReturn;
  }

  const [options, setOptions] = useState(prepareOptions(parentParams?.options));

  const updateOptions = (option, value = null) => {
    setOptions((prevOptions) => {
      if (option === 'specificPageProcessing') {
        return {
          ...prevOptions,
          specificPageProcessing: !prevOptions.specificPageProcessing,
          specificPageProcessingOptions: !prevOptions.specificPageProcessing ? { from: 1, to: 2 } : null, // Set to null if toggled off
        };
      }

      if (option === 'specificPageProcessingOptions') {
        return {
          ...prevOptions,
          specificPageProcessingOptions: {
            ...prevOptions.specificPageProcessingOptions,
            ...value,
          },
        };
      }

      return {
        ...prevOptions,
        [option]: !prevOptions[option],
      };
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedParams = {
      name,
      options: options,
      description,
      lang: langObject.name,
      fields: parentParams?.fields,
    };

    nextStep(updatedParams);
  }

  const maxLengthForName = 50;
  const maxLengthForDescription = 100;

  useEffect(() => {
    if (parentParams) {
      if (parentParams.name) setName(parentParams.name);
      if (parentParams.description) setDescription(parentParams.description);
      if (parentParams.options) setOptions(prepareOptions(parentParams.options));
      if (parentParams.lang) {
        let newLang = languages.find(l => l.name === parentParams.lang);
        if (newLang) setLangObject(newLang);
      }
    }
  }, [parentParams]);

  const environment = process.env.REACT_APP_ENV;

  return (
    <div className="bg-white sm:rounded-lg">
      <div className="">
        {/* back button */}
        {!isEdit && (
          <button
            onClick={previousStep}
            className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Back
          </button>
        )}

        <h3 className="text-base font-semibold leading-6 text-gray-900">{isEdit ? "Edit Extraction" : "New Extraction"}</h3>
        <div className="mt-1 max-w-xl text-sm text-gray-500">
          <p>Give your extraction a name, a description and select the language.</p>
        </div>

        <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSubmit}>
          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-1 w-full">

            <div className="sm:col-span-4">
              <div className="flex justify-between">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Name
                </label>
                <span className="text-sm leading-6 text-gray-400" id="email-optional">
                  {name.length}/{maxLengthForName}
                </span>
              </div>
              <div style={{ width: "100%" }}>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    maxLength={maxLengthForName}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <div className="col-span-full">
                <div className="flex justify-between">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Description
                    <span className='ml-1 text-xs font-normal text-gray-400'>
                      {"(optional)"}
                    </span>
                  </label>
                  <span className="text-sm leading-6 text-gray-400" id="email-optional">
                    {description.length}/{maxLengthForDescription}
                  </span>
                </div>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    maxLength={maxLengthForDescription}
                    rows={1}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <LangSelect2
                langObject={langObject}
                setLangObject={setLangObject}
                languages={languages}
              />
            </div>

            <div className="sm:col-span-4">
              <div className="flex justify-between">
                <label htmlFor="options" className="block text-sm font-medium leading-6 text-gray-900">
                  Document Options
                  <span className='ml-1 text-xs font-normal text-gray-400'>
                    {"(optional)"}
                  </span>
                </label>
              </div>
              <div className="mt-2">
                <div className="relative">
                  <OptionsSelect
                    options={options}
                    updateOptions={updateOptions}
                  />
                </div>
              </div>
            </div>

            {environment === "development" && (
              <div className="sm:col-span-4">
                <div className="flex justify-between">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Workflow ID
                  </label>
                  <span className="text-sm leading-6 text-gray-400" id="email-optional">
                    {name.length}/{maxLengthForName}
                  </span>
                </div>
                <div style={{ width: "100%" }}>
                  <div className="mt-2">
                    <input
                      id="workflowId"
                      name="workflowId"
                      value={workflowId}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="sm:col-span-4 mt-3">
              <div className="col-span-full flex justify-end">
                <button
                  type="submit"
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

const OptionsSelect = ({ options, updateOptions }) => {
  const [errors, setErrors] = useState({});

  function findOptionLabel(option) {
    if (option === 'hasTable') return 'Does the document contain tables?';
    if (option === 'handwrittenTextRecognition') return 'Is the document handwritten?';
    if (option === 'checkboxRecognition') return 'Does the document contain checkboxes?';
    if (option === 'specificPageProcessing') return 'Do you want to process only specific pages? (PDFs only)';
    return 'not found';
  }

  const validatePageRange = (name, value) => {
    let newErrors = { ...errors };
    const parsedValue = parseInt(value, 10);

    if (name === 'from') {
      if (parsedValue < 1 || parsedValue > 100) {
        newErrors.from = 'From page must be between 1 and 100';
      } else {
        delete newErrors.from;
      }
    }
    if (name === 'to') {
      if (parsedValue < parseInt(options.specificPageProcessingOptions.from, 10) || parsedValue > 100) {
        newErrors.to = 'To page must be greater than or equal to "From" page and less than or equal to 100';
      } else {
        delete newErrors.to;
      }
    }
    setErrors(newErrors);
  };

  const handlePageRangeChange = (e) => {
    const { name, value } = e.target;

    // Convert the value to a number
    const numericValue = parseInt(value, 10);

    // Validate the value
    validatePageRange(name, numericValue);

    // Update the state with the numeric value
    updateOptions('specificPageProcessingOptions', { [name]: numericValue });
  };

  return (
    <div className="space-y-1">
      {Object.entries(options).map(([key, value]) => {
        if (key === 'specificPageProcessingOptions') return null;
        return (
          <fieldset key={key} className="">
            <legend className="sr-only">Advanced settings</legend>
            <div className="space-y-5">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    checked={value}
                    onChange={() => updateOptions(key)}
                    id={key}
                    aria-describedby={`${key}-description`}
                    name={key}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    required={key === 'specificPageProcessing' && value}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor={key} className="flex flex-col font-medium text-gray-900">
                    {findOptionLabel(key)}
                    {key === 'specificPageProcessing' && (
                      <span className='ml-1 text-xs font-normal text-gray-400'>
                        {"The document will be automatically trimmed to include only the selected pages."}
                      </span>
                    )}
                  </label>
                  {key === 'specificPageProcessing' && value && (
                    <div className="mt-2 flex space-x-4">
                      <div>
                        <label htmlFor="fromPage" className="text-gray-600 mr-2">
                          From:
                        </label>
                        <input
                          type="number"
                          name="from"
                          id="fromPage"
                          value={options.specificPageProcessingOptions.from}
                          onChange={handlePageRangeChange}
                          className={`w-16 px-3 py-2 border ${errors.from ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                          min="1"
                          max="100"
                        />
                        {errors.from && <p className="text-red-500 text-xs mt-1">{errors.from}</p>}
                      </div>
                      <div>
                        <label htmlFor="toPage" className="text-gray-600 mr-2">
                          To:
                        </label>
                        <input
                          type="number"
                          name="to"
                          id="toPage"
                          value={options.specificPageProcessingOptions.to}
                          onChange={handlePageRangeChange}
                          className={`w-16 px-3 py-2 border ${errors.to ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                          min={parseInt(options.specificPageProcessingOptions.from) || 1}
                          max="100"
                        />
                        {errors.to && <p className="text-red-500 text-xs mt-1">{errors.to}</p>}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </fieldset>
        )
      })}
    </div>
  );
};

function LangSelect2({ langObject, setLangObject, languages }) {
  const [query, setQuery] = useState('')

  languages.sort((a, b) => a.name.localeCompare(b.name));
  languages = languages.sort((a, b) => a.id === 0 ? -1 : b.id === 0 ? 1 : 0);

  const filteredLanguages =
    query === ''
      ? languages
      : languages.filter((language) => {
        return language.name.toLowerCase().includes(query.toLowerCase())
      });

  return (
    <Combobox as="div" value={langObject} onChange={(e) => {
      setLangObject(e)
    }}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Language</Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(language) => language?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredLanguages.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredLanguages.map((language) => (
              <Combobox.Option
                key={language.id}
                value={language}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active }) => (
                  <>
                    <div className="flex items-center">
                      {language.name === 'Multi-Lingual' && <GlobeIcon className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Romanian' && <RO title="Romania" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'English' && <US title="United States" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'French' && <FR title="France" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Spanish' && <ES title="Spain" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Arabic' && <SA title="Arab Emirates" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Portuguese' && <PT title="Portugal" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'German' && <DE title="Germany" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Italian' && <IT title="Italy" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Russian' && <RU title="Russia" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Ukrainian' && <UA title="Ukraine" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Polish' && <PL title="Poland" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Czech' && <CZ title="Czech Republic" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Hungarian' && <HU title="Hungary" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Bulgarian' && <BG title="Bulgaria" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Serbian' && <RS title="Serbia" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Croatian' && <HR title="Croatia" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Turkish' && <TR title="Turkey" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Hindi' && <IN title="India" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Nepali' && <NP title="Nepal" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Vietnamese' && <VN title="Vietnam" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Filipino' && <PH title="Philippines" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Urdu' && <PK title="Pakistan" className="h-6 w-6 flex-shrink-0" />}
                      {language.name === 'Bangla' && <BD title="Bangladesh" className="h-6 w-6 flex-shrink-0" />}
                      <span className="sr-only">{language.name}</span>

                      <span className={classNames('ml-3 truncate', (langObject.name === language.name) && 'font-semibold')}>{language.name}</span>
                    </div>

                    {langObject.name === language.name && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

export default FirstStep
