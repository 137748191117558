import React from "react";
import { useState, useEffect, useContext } from 'react'

import { ref, onValue } from "firebase/database";

import { db } from '../utils/firebaseApp.js';

import { AuthContext } from "../utils/auth.js";

import { PlusIcon } from '@heroicons/react/20/solid'

export default function CreditsComponent() {
    const { currentUser } = useContext(AuthContext);
    let userId = currentUser.uid;

    const [credits, setCredits] = useState(0);

    useEffect(() => {
        const creditsRef = ref(db, 'userDetails/' + userId + '/credits/credits');

        const unsubscribe = onValue(creditsRef, (snapshot) => {
            const data = snapshot.val();
            if (data !== null) {
                let rawCredits = data || 0;
                let processedCredits = rawCredits / 10;

                setCredits(processedCredits);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <a href="/billing" className="group flex items-center cursor-pointer relative transition duration-300 ease-in-out">
            <span className="text-sm text-gray-900 group-hover:text-indigo-500 transition-colors duration-300 ease-in-out">{credits.toLocaleString()} pages</span>
            <div className="absolute hidden group-hover:block bg-white text-gray-900 text-xs rounded py-1 px-2 top-full left-1/2 transform -translate-x-1/2 translate-y-1 border border-gray-300 whitespace-nowrap">
                Buy pages
            </div>
            <div type="button" className="p-2 pr-0 text-gray-400 hover:text-gray-500 transition-colors duration-300 ease-in-out">
                <span className="sr-only">Add pages</span>
                <button
                    type="button"
                    className="rounded-full bg-white p-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 group-hover:ring-indigo-500 group-hover:shadow-lg transition-all duration-300 ease-in-out"
                >
                    <PlusIcon className="h-4 w-4 group-hover:text-indigo-500 transition-all duration-300 ease-in-out" aria-hidden="true" />
                </button>
            </div>
        </a>
    );
};
