import { useState, useContext } from "react";
import { AuthContext } from "../../utils/auth.js";
import axios from "axios";

import Lottie from 'react-lottie';
import uploadSuccessfulData from './upload_successful.json';
import loadingData from './loading.json';

export default function SaveExtraction({ params, previousStep, nextStep }) {
    const { currentUser } = useContext(AuthContext);

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const environment = process.env.REACT_APP_ENV;

    // progress
    const [startedUpload, setStartedUpload] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isError, setIsError] = useState(false);

    const updateExtraction = () => {
        currentUser.getIdToken().then((idToken) => {
            const url = serverUrl + "/updateExtraction";

            setStartedUpload(true);

            axios.post(url, {
                "extractionDetails": {
                    "name": params.name,
                    "description": params.description,
                    "language": params.lang,
                    "fields": params.fields,
                    "options": params.options,
                    "updatedAt": Date.now(),
                },
                "extractionId": params.extractionID,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then((response) => {
                if (response.status === 200) {
                    setIsFinished(true);
                    setIsError(false);
                    setTimeout(() => {
                        nextStep({ closeModal: true });
                    }, 1000);
                } else {
                    setIsFinished(true);
                    setIsError(true);
                }
            }).catch((error) => {
                setIsFinished(true);
                setIsError(true);
            });
        });
    }

    const defaultOptionsUploadSuccessful = {
        loop: false,
        autoplay: true,
        animationData: uploadSuccessfulData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptionsLoading = {
        loop: true,
        autoplay: true,
        animationData: loadingData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className="bg-white sm:rounded-lg">
            <div className="">
                {!startedUpload &&
                    <>
                        <div className='col-span-full flex justify-center mt-5'>
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900">Confirmation</h3>
                        </div>
                        <div className="col-span-full flex flex-col items-center justify-center mt-4 mb-6 text-base text-gray-500">
                            <p>
                                You will update this extraction
                            </p>
                        </div>
                    </>
                }

                {startedUpload && isFinished &&
                    <>
                        <div className="col-span-full flex justify-center items-center mt-2 text-sm text-gray-500">
                            <h3 className="text-2xl font-semibold leading-6 text-gray-900">Extraction Updated</h3>
                            <Lottie
                                options={defaultOptionsUploadSuccessful}
                                style={{ margin: "0 0 0 10px" }}
                                height={48}
                                width={48}
                            />
                        </div>
                    </>
                }

                {!startedUpload &&
                    <div className="col-span-full flex justify-center mt-4">
                        <button
                            onClick={() => previousStep()}
                            className="ml-4 inline-flex items-center rounded-md border hover:bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        >
                            Back
                        </button>
                        <button
                            onClick={() => updateExtraction()}
                            className="ml-4 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Update
                        </button>
                    </div>
                }

                {startedUpload &&
                    <div className="col-span-full flex justify-center mt-4"></div>
                }

                {startedUpload && !isFinished &&
                    <div className="col-span-full flex justify-center mt-5 text-sm text-gray-500">
                        <Lottie
                            options={defaultOptionsLoading}
                            style={{ margin: "0 0 0 10px" }}
                            height={96}
                            width={96}
                        />
                    </div>
                }

                {startedUpload && isError &&
                    <div className="mt-5" >
                        <p className="text-center mt-2 text-sm text-gray-500">
                            There was an error uploading your batch.
                        </p>
                        <p className="text-center mt-2 text-sm text-gray-500">
                            Please try again later.
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}
