// export libraries
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const saveFile = (data, fileName, mimeType) => {
    const blob = new Blob([data], { type: mimeType });
    saveAs(blob, fileName);
};

export const handleGenerateJson = (outputMap, fileName, fields) => {
    const json = JSON.stringify(outputMap, null, 2);
    saveFile(json, fileName + '.json', 'application/json');
};

export const handleGenerateExcel = (outputMap, fileName, fields) => {
    // Flatten the data structure for CSV format
    const flattenedData = outputMap.map(fileData => {
        const row = { file_name: fileData.file_name };
        fields.forEach(field => {
            if (fileData.data == null) fileData.data = {};
            const fieldValue = fileData.data[field.key];
            // Check if fieldValue is an object and not null
            if (typeof fieldValue === 'object' && fieldValue !== null) {
                // Convert object to JSON string
                row[field.key] = JSON.stringify(fieldValue);
            } else {
                // Use the value as is
                row[field.key] = fieldValue;
            }
        });
        return row;
    });

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    // Convert flattened data to spreadsheet
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Save as Excel file
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + '.xlsx');
};

export const handleGenerateCsv = (outputMap, fileName, fields) => {
    // Flatten the data structure for CSV format
    const flattenedData = outputMap.map(fileData => {
        const row = { file_name: fileData.file_name };
        fields.forEach(field => {
            if (fileData.data == null) fileData.data = {};
            const fieldValue = fileData.data[field.key];
            if (typeof fieldValue === 'object' && fieldValue !== null) {
                // Convert object to JSON string and escape double quotes
                row[field.key] = JSON.stringify(fieldValue).replace(/"/g, '""');
            } else {
                // Convert to string and escape double quotes
                // row[field.key] = `"${fieldValue}"`.replace(/"/g, '""');
                row[field.key] = fieldValue;
            }
        });
        return row;
    });

    // Extract headers from flattened data
    const headers = Object.keys(flattenedData.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
    const csvRows = [];

    // Add the headers to the csvRows array
    csvRows.push(headers.join(','));

    // Convert the flattened data to CSV rows
    flattenedData.forEach(row => {
        csvRows.push(headers.map(header => `"${row[header] ? row[header] : ""}"`).join(','));
    });

    // Convert the rows array to a single CSV string
    const csvString = csvRows.join('\n');

    saveFile(csvString, fileName + '.csv', 'text/csv;encoding:utf-8');
};